// OSS microservice API data transformation

import {
  digObject,
  getProp,
  getPropObject,
  strval,
  isNone,
  listSize,
  mergeObjects,
  isObject,
  copyProp,
  getTreeProp,
  lowerCase,
  hasProp,
  jsonStringify,
  isNumeric,
} from "./util";
import { isResidential, isCondo } from "./features-config";
import getLogger from "./debug-logger";

const log = getLogger("oss-ms-transform", 3);

//----------------------------------------------------------
// extract/massage products before further transformations
//----------------------------------------------------------
export const extractMsResult = (respData) => {
  const data = digObject(respData, "Results", 0);
  const result = getPropObject(data, "Result") || {
    Message: "Dataset transformation failed",
  };
  copyProp("Success", data, result);
  delete result.Debug;
  // Unauthorized: message with list of excluded products, if any
  const unauth = getProp(respData, "Unauthorized");
  if (unauth) {
    result.Unauthorized = strval(unauth.split(":")[1]).trim();
  }
  return result;
};

//----------------------------------------------------------
// extract geocode from Address object
//----------------------------------------------------------
const extractAddressGeocode = (addrObj) => {
  const geocode = digObject(addrObj, "Geocodes", 0, "Geocode"); // as {Latitude, Longitude}
  if (isObject(geocode)) {
    const { Latitude: lat, Longitude: lng } = geocode;
    return { lat, lng };
  }
  return null;
};
//----------------------------------------------------------
// extract OAK (ID) and location from Address object (for Comparables)
//----------------------------------------------------------
const extractAddressLocation = (addrObj) => {
  const ret = {
    OptaAddressKey: getProp(addrObj, "ID"),
    StreetAddress: getProp(addrObj, "RequestedLine"),
  };
  const geocode = extractAddressGeocode(addrObj);
  mergeObjects(ret, geocode);
  return ret;
};

//----------------------------------------------------------
// extract PDF report URL from products
//----------------------------------------------------------
export const extractReportUrl = (resultset) =>
  digObject(resultset, "IClarifyResidentialReport", "Report", "Content", "ContentURL");

//----------------------------------------------------------
// transform single Comparable,
// translate microservice keys to those from OSS for backwards compatibility
//----------------------------------------------------------
const transformComparable = (compItem) => {
  const addr = digObject(compItem, "Addresses", 0);
  const building = digObject(compItem, "Building", 0);
  const result = {};

  result.DistanceFromSubjectProperty = getProp(compItem, "DistanceFromSubjectProperty");

  const propType = getProp(compItem, "PropertyTypeCode");

  log(compItem); //----------------- log

  // OptaAddressKey, StreetAddress, Latitude, Longitude
  mergeObjects(result, extractAddressLocation(addr));

  if (!isCondo(propType)) {
    result.LotSize = getProp(compItem, "LotSize");
    result.LotDepth = getProp(compItem, "LotDepth");
    result.LotFrontage = getProp(compItem, "LotFrontage");
  }
  // last listing and sold prices and dates
  const propValueItems = digObject(compItem, "PropertyValues", "AdditionalPropertyValueItems");
  if (listSize(propValueItems)) {
    propValueItems.forEach((it) => {
      const code = getProp(it, "PropertyValueTypeCode");

      if (code !== "SoldPrice" && code !== "ListingPrice") {
        return;
      }
      const item = digObject(it, "PropertyValueItems", 0); // use 1st array element only
      const val = getProp(item, "Value");
      const date = getProp(item, "Date");
      if (!isNone(date)) {
        result[code] = val;
        result[code.replace("Price", "Date")] = date;
      }
    });
    // setting Value same as SoldPrice for further data processing
    result.Value = result.SoldPrice;
  }

  result.ImageURL = digObject(building, "Images", 0, "ImageURL"); // property image

  log(`PropertyTypeCode "${propType}"`); //----------------- log

  let feats;
  if (isResidential(propType)) {
    feats = digObject(building, "ResidentialBuilding", "ResidentialConstructionFeatures");
    result.ArchitecturalStyleType = getProp(feats, "ArchitecturalStyleTypeCode");
    result.YearBuilt = getProp(feats, "YearBuilt");
    result.StoreyCount = digObject(feats, "BuildingStoreyConstruction", "StoreyCountTypeCode");
    // both TotalFloorArea and SquareFootage available, same value
    result.SquareFootage = getProp(feats, "SquareFootage");
    result.FinishedBasement = getProp(feats, "FinishedBasement");
    result.NumberOfBedrooms = digObject(feats, "BedroomConstruction", "NumberOfBedrooms");
    result.BathroomCount = digObject(feats, "BathroomConstruction", "BathroomCountTypeCode");
    result.ExteriorWallType = digObject(
      feats,
      "ExteriorWallConstructions",
      0,
      "ExteriorWallTypeCode"
    );
    result.GarageType = digObject(feats, "Garages", 0, "GarageTypeCode") || "";
    result.GarageNumberOfCars = digObject(feats, "Garages", 0, "NumberOfCars") || 0;
  } else if (isCondo(propType)) {
    const multiResBuilding = getProp(building, "MultiResidentialBuilding");
    result.MultiResidentialStyleType = getProp(multiResBuilding, "MultiResidentialStyleTypeCode");

    const buildingFeats = getProp(multiResBuilding, "CommercialConstructionFeatures");
    result.YearBuilt = getProp(buildingFeats, "YearBuilt");
    result.NumberOfStoreys = getProp(buildingFeats, "NumberOfStoreys");

    result.ParkingType = getTreeProp(buildingFeats, "ParkingTypeCode");

    const multiResUnit = getProp(multiResBuilding, "MultiResidentialUnit");
    result.FloorLevel = getProp(multiResUnit, "FloorLevel");

    const unitFeats = getProp(multiResUnit, "ResidentialConstructionFeatures");
    result.TotalFloorArea = getProp(unitFeats, "TotalFloorArea"); // same as SquareFootage
    result.NumberOfBathrooms = digObject(
      unitFeats,
      "BathroomConstruction",
      "BathroomCountTypeCode"
    );
    result.NumberOfBedrooms = digObject(unitFeats, "BedroomConstruction", "NumberOfBedrooms");
    result.NumberOfDens = digObject(unitFeats, "BedroomConstruction", "NumberOfDens");
  }
  log(`Comparable:`, result); //------------- log

  return result;
};

//----------------------------------------------------------
// extract and transform Comparables
//----------------------------------------------------------
export const extractComparables = (resultset) => {
  let comps = null;
  const props = digObject(resultset, "Properties", "Properties"); // array
  if (listSize(props)) {
    comps = props.map((it) => transformComparable(it));
  }
  return comps;
};

export const CRESTA = "CRESTA Accumulation Zone";

//----------------------------------------------------------
// extract and transform Wildfire score
//----------------------------------------------------------
export const transformEnvironmental = (resultset) => {
  const updates = {};
  // try Report URL
  const reportUrl = extractReportUrl(resultset);
  if (reportUrl) {
    updates.REPORT_URL = reportUrl;
  }
  // extract Earthquake Risk
  const eqArr = getTreeProp(resultset, "EarthquakeCodes"); // array of objects expected
  if (listSize(eqArr)) {
    eqArr.forEach((it) => {
      const sysName = getProp(it, "SystemName") || "";
      if (sysName === CRESTA) {
        const zone = getProp(it, "Zone");
        const descr = getProp(it, "Description");
        updates.EARTHQUAKE_SYSTEM = sysName;
        updates.EARTHQUAKE_ZONE = zone;
        updates.EARTHQUAKE_RISK = descr;
        log(`Earthquake Zone: '${zone}', risk: '${descr}'`); //---------- log
        return;
      }
    });
  }
  // extract Wildfire Score and Risk
  const fireScoresArr = getTreeProp(resultset, "WildfireScores");
  if (listSize(fireScoresArr)) {
    fireScoresArr.forEach((obj) => {
      if (getProp(obj, "ScoreTypeCode") !== "RiskAdjusted") return; // ignore such entries
      const score = getProp(obj, "Score");
      const risk = lowerCase(getProp(obj, "SeverityCode"));
      if (!isNone(score)) updates.WILDFIRE_SCORE = score;
      if (!isNone(risk)) updates.WILDFIRE_RISK = risk;
    });
  }
  // extract Active Wildfires
  const activeWildfires = getTreeProp(resultset, "ActiveWildfires");
  if (hasProp(activeWildfires, "ActiveFires", "FireDanger")) {
    updates.ACTIVE_WILDFIRES_JSON = jsonStringify(activeWildfires);
  }

  // extract Flood Score and Map
  const floodObj = getTreeProp(resultset, "FloodScores");

  const floodMapItems = getProp(floodObj, "FloodMap"); // array expected

  if (listSize(floodMapItems)) {
    for (let it of floodMapItems) {
      const fc = getProp(it, "FloodContent");
      if (getProp(fc, "ImageTypeCode") === "Overlay") {
        updates.FLOOD_MAP = getProp(fc, "ContentURL");
      } else {
        const code = getProp(it, "FloodEventCode");
        if (isNumeric(code)) {
          updates[`FLOOD_EVENT_${code}`] = getProp(it, "FloodEventProbability");
        }
      }
    }
  }

  const floodScore = digObject(floodObj, "FloodScore", 0, "FloodScore");
  if (isNone(floodScore)) {
    // handle data error
    updates.FLOODSCORE_ERROR = getProp(floodObj, "ProductMessage") || "FLOODSCORE_ERROR";
  } else {
    updates.FLOOD_SCORE = floodScore;
  }

  return updates;
};
