import React, { useState, useEffect, useCallback } from "react";
import { useGlobal } from "reactn";
import cx from "classnames";
import useLanguage from "../useLanguage";
import { isEmpty } from "../../lib/util";
import { addPropertyReview } from "../../lib/overlord-api";
import PropertyOverlordButton from "./PropertyOverlordButton";

import getLogger from "../../lib/debug-logger";

import "../../styles/avm/property-review.scss";

const log = getLogger("PropertyReview", 1);

const reasonTextMaxSize = 250;

const PropertyReview = (props) => {
  const [overlord] = useGlobal("OVERLORD");

  const [reviewAlloved] = useGlobal("REVIEW_ALLOWED");

  const [reviewEnabled] = useGlobal("REVIEW_ENABLED");

  const [lastFeatsSnapshot] = useGlobal("FEATURES_CURRENT");

  const { t, language } = useLanguage();

  const [buttonEnabled, setButtonEnabled] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);

  const [clicked, setClicked] = useState(false);

  // 'reason' text
  const [modalText, setModalText] = useState("");

  useEffect(() => {
    const b = !modalVisible && reviewEnabled && !clicked;
    log(`Review button ${b ? "en" : "dis"}abled`); //------------------------- log
    setButtonEnabled(b);
  }, [modalVisible, reviewEnabled, clicked, setButtonEnabled]);

  // call API and render result
  const callApi = async (opts) => {
    const result = await addPropertyReview(opts, true); // compare including unsaved changes
    log("API response data", result); //------------------------- log
  };

  const handleReviewClick = (ev) => {
    log("Review button clicked"); //------------------------- log
    setModalVisible(true);
  };

  const handleModalInput = (ev) => {
    const text = ev.target.value;
    const count = text.length;
    if (count <= reasonTextMaxSize) {
      setModalText(text);
    }
  };

  const handleSubmit = useCallback(
    (ev) => {
      const reason = modalText.trim();
      if (isEmpty(reason)) {
        log("reason for updates required"); //------------- log
      }
      callApi({ ReviewReason: reason, Status: "Pending" });
      log("submitting updates"); //------------------------- log
      setModalText("");
      setModalVisible(false);
      setClicked(true);
      setTimeout(() => {
        setClicked(false);
      }, 2000);
    },
    [modalText]
  );

  const handleCancel = (ev) => {
    log("cancelling submit"); //------------------------- log
    setModalVisible(false);
  };

  //----------------------------------------------------
  if (!reviewAlloved || !lastFeatsSnapshot) return null;
  //----------------------------------------------------

  return (
    <div className={cx("property-review", language)}>
      {!!overlord && <PropertyOverlordButton />}
      <button
        className={cx("big-button", language)}
        onClick={handleReviewClick}
        disabled={!buttonEnabled}
      >
        {t("Submit for Review")}
      </button>

      {modalVisible && (
        <div className="property-review-modal">
          <div className="prm-header">
            <h3>{t("Reason for Review")}</h3>
          </div>
          <div className="prm-input">
            <textarea value={modalText} onInput={handleModalInput}></textarea>
          </div>
          <div className="prm-counter">
            {reasonTextMaxSize - modalText.length + " characters left"}
          </div>
          <div className="prm-controls">
            <button className={language} onClick={handleSubmit} disabled={!modalText.trim()}>
              {t("Submit")}
            </button>
            <button className={language} onClick={handleCancel}>
              {t("Cancel")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PropertyReview;
