import { getGlobal, setGlobal as setGlobalReactN } from "reactn";
import {
  getProp,
  isNone,
  isObject,
  isEmpty,
  jsonParse,
  copyProps,
  isArray,
  digObject,
  listSize,
  hasProp,
  arrayUnique,
  makeObject,
  strlen,
} from "./util";
import {
  getConstructionFeaturesKeys,
  getFeatsStatsKeys,
  isCondo,
  isResidential,
} from "./features-config";
import getLogger from "./debug-logger";
import { getApiErrorKeys } from "./globals";

const log = getLogger("global-util", 1);

export { getGlobal };

//--------------------------------------------------------------
// log global update
export const setGlobal = (obj) => {
  if (!isObject(obj)) {
    log(`global updates must be object`); //----------- log
    return;
  }
  return setGlobalReactN(obj);
};

//--------------------------------------------------------------
// read global value
export const getGlobalValue = (key, fallback = null) => {
  const glob = getGlobal();
  return key in glob ? glob[key] : fallback;
};

//--------------------------------------------------------------
// set global value (conditionally - if not set)
export const setGlobalValue = (key, val) => {
  if (!strlen(key)) {
    log(`global key must be non-empty string`, key); //------------ log
    return;
  }
  setGlobal({ [key]: val });
};

//--------------------------------------------------------------
// verify current page signature
export const isPageName = (...names) => {
  const pageName = getGlobalValue("PAGE_NAME");
  return names.includes(pageName);
};

//--------------------------------------------------------------
// set global message (remove after timeout)
export const setMessage = (msg = "", isError = false) => {
  setGlobalValue(isError ? "ERROR" : "MESSAGE", msg);
};

//--------------------------------------------------------------
// set global error (remove after timeout)
export const setError = (errMsg = "") => {
  setMessage(errMsg, true);
};

//--------------------------------------------------------------
// reset error messages
export const resetApiGlobals = (keysArr = null) => {
  const keys = listSize(keysArr) ? keysArr : getApiErrorKeys();
  const obj = makeObject(keys);
  setGlobal(obj);
};

//--------------------------------------------------------------
// check if construction features altered but not saved
export const isDataDirty = () => !isEmpty(getGlobalValue("DIRTY_KEYS"));

//--------------------------------------------------------------
// get global language
export const getLanguage = () => getGlobalValue("LANGUAGE");

//--------------------------------------------------------------
// is current language French?
export const isFrench = () => getLanguage() === "fr";

//--------------------------------------------------------------
export const getUser = () => getGlobalValue("USERNAME");

//--------------------------------------------------------------
export const getUserInfo = (key = null) => {
  const info = getGlobalValue("USER_INFO");
  return isNone(key) ? info : getProp(info, key);
};

//--------------------------------------------------------------
// extract user attributes
export const getUserAttributes = () => getUserInfo("user_attributes");

export const getUserAttribute = (key = null) => {
  const info = getUserAttributes();
  return isNone(key) ? info : getProp(info, key);
};

//--------------------------------------------------------------
// Auth access token
export const getAuthToken = () => getUserInfo("access_token");

//--------------------------------------------------------------
// translate list of common product names into list of actual
// product signatures, filter off products not available for current user
export const filterAvailableProducts = (names, isMicroservice = false) => {
  const prodMap = getGlobalValue("PRODUCTS_MAP");
  if (!isArray(names) || !isObject(prodMap)) return null;
  const prodType = isMicroservice ? "osb" : "oss";
  const products = names
    .map((it) => {
      return digObject(prodMap, it, prodType);
    })
    .filter(Boolean);
  return listSize(products) ? arrayUnique(products) : null;
};
//--------------------------------------------------------------
// Checking if OSS/OSB products are available for current user
//--------------------------------------------------------------
export const userHasProduct = (productName) => hasProp(getGlobalValue("PRODUCTS_MAP"), productName);

//--------------------------------------------------------------
// check if session is not expired
export const isSessionValid = () => {
  const exptime = Number(getGlobalValue("SESSION_EXPIRES")) || 0;
  return exptime > Date.now();
};

//--------------------------------------------------------------
// check branding info (added by auth proxy lambda)
export const getBrandingInfo = () => {
  const info = getUserInfo();
  const ret = {};
  ["brand_id", "brand_logo_image", "brand_logo_size", "brand_footer_text"].forEach((k) => {
    ret[k] = getProp(info, k, "");
  });
  return ret;
};

//--------------------------------------------------------------
// check for branding ID only
export const getBrandingId = () => getProp(getUserInfo(), "brand_id");

//--------------------------------------------------------------
// start/stop busy state (turn spinner on/off)
export const setBusy = (turnOn = true) => {
  let callCount = getGlobalValue("BUSY") || 0;
  if (turnOn) callCount += 1;
  else if (callCount > 0) callCount -= 1;
  else callCount = 0;
  setGlobalValue("BUSY", callCount);
};

//--------------------------------------------------------------
// add/remove invalid input
export const updateInvalidList = (formKey, isValid) => {
  const lst = getGlobalValue("INVALID_INPUTS") || [];
  const isOnList = lst.includes(formKey);
  if (isOnList ^ isValid) return;
  const keySet = new Set(lst);
  isValid ? keySet.delete(formKey) : keySet.add(formKey);
  setGlobalValue("INVALID_INPUTS", [...keySet]); // use new array to update reference
};

//--------------------------------------------------------------
// get property type
//--------------------------------------------------------------
export const getPropertyType = () => getGlobalValue("PROPERTY_TYPE");

export const isPropertyCondo = () => isCondo(getPropertyType());

export const isPropertyResidential = () => isResidential(getPropertyType());

//--------------------------------------------------------------
// get current values of construction features
//--------------------------------------------------------------
export const getCurrentPropertyDetails = () => {
  const glob = getGlobal();
  const propType = getProp(glob, "PROPERTY_TYPE");
  const featKeys = [...getConstructionFeaturesKeys(propType), ...getFeatsStatsKeys(propType)];
  return copyProps(featKeys, glob);
};

//--------------------------------------------------------------
// get updated (and saved as current snapshot) construction features
//--------------------------------------------------------------
export const getUpdatedPropertyFeatures = (unsaved = false) => {
  const initial = jsonParse(getGlobalValue("FEATURES_INITIAL"));
  const current = unsaved ? getCurrentPropertyDetails() : jsonParse(getGlobalValue("FEATURES_CURRENT"));
  if (!isObject(initial) || !isObject(current)) {
    return null;
  }
  const updated = {};
  for (let k in initial) {
    if (current[k] !== initial[k]) {
      updated[k] = current[k];
    }
  }
  return updated;
};

//--------------------------------------------------------------
// build comparable object for subject property
//--------------------------------------------------------------
export const getComparableFeatures = () => {
  const glob = getGlobal();
  const propType = getProp(glob, "PROPERTY_TYPE");
  const featureKeys = getConstructionFeaturesKeys(propType);
  const constrFeats = jsonParse(getProp(glob, "FEATURES_CURRENT"));
  const selectedFeats = copyProps(featureKeys, constrFeats);
  return {
    StreetAddress: getGlobalValue("ADDRESS_LINE"),
    ImageURL: getProp(glob, "IMAGE_STREET"),
    Value: getProp(glob, "AVM_VALUE"),
    ...selectedFeats,
  };
};

//--------------------------------------------------------------
// drop Comparables
//--------------------------------------------------------------
export const dropComparables = () => {
  setGlobal({
    COMPARABLES: null,
    COMPARABLES_SELECTION: null,
    COMPARABLES_OAKS: null,
    COMPARABLES_ERROR: null,
  });
};
